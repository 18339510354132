.add-trip {
    &-container {
        display: flex;
        width: 100%;
        // height: 96vh;

        form {
            display: flex;
            width: 70vw;
            height: 100vh;
            padding: 2rem;
            justify-content: center;
            margin: auto;

            .right {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
                width: 55%;

                .files {
                    &-status {
                        max-height: 25%;
                        overflow: auto;
                        border: 2px solid $color-primary;
                        border-radius: 10px;
                        padding: 0 1rem;
                        max-width: 70%;
                        min-width: 70%;
                        font-size: 1rem;

                        &:empty {
                            display: none;
                        }
                    }

                    &-file {
                        text-align: center;
                    }
                }

                .reset-files {
                    font-size: 0.8rem;
                }

                svg.CircularProgressbar {
                    position: absolute;
                    width: 10rem;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .CircularProgressbar-container {
                    display: none;
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba($color-white, 0.7);
                    z-index: 5;

                    &.show {
                        display: block;
                    }

                    &>p {
                        position: absolute;
                        top: 30%;
                        right: 50%;
                        transform: translate(50%, -50%);
                        font-size: 2rem;
                        line-height: 2.2rem;
                    }
                }
            }

            .left {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
                height: 100%;
                width: 70%;

                .WysiwygAddFlight {
                    width: 100%;
                }

                .input-container {
                    width: 100%;
                    height: 50%;

                    .textarea {
                        width: 100%;
                        height: 50%;
                        border: 1px solid black;
                        border-radius: 10px;
                        padding: 1rem;
                        font-size: 1rem;
                        resize: none;
                    }
                }


                &-dropzone {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    gap: 1rem;
                    height: 40%;
                    width: 100%;
                    // padding-top: 10%;
                    border: 1px dashed black;
                    border-radius: 10px;

                    .text {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align-last: center;
                        height: 20%;
                        justify-content: space-evenly;
                    }
                }
            }


        }
    }
}

.lottie {
    &-uploading {
        width: 15rem;
    }
}