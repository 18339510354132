//colors
// $color-primary: #67d2c4;
$color-primary-light: #0a56b8;
$color-primary: #03428E;
$color-primary-part-transparent: #08438fcd;

$color-secondary: #D0B535;
$color-secondary-part-transparent: #26ace1ce;

$color-grey-light-1: #DCDDDE;
$color-grey-light-2: #ececec;
$color-grey-carousel: #ececec;
$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;
$color-grey-dark-4: #3d3d3d;
$color-footer-dark: #252525;

$color-white: #ffffff;
$color-black: #000;
$color-black-main: #201b22;

$color-placeholder: #7B7C80;
//error
// $error-border: #be1111;
$error-bg: #dc3546;
$error-border: #dc354585;
// $error-bg: $color-white;
$color-success: #06c00e;

// $error-bg : ;

//font
$defaut-font-size: 1.6rem;
//grid
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

//perspectiv
$perspectiv-normal: 150rem;

//tab- height
$tab-tooltip-height: 0.4rem;
$tab-btn-height: 2.6rem;
$tab-total-height: $tab-btn-height+$tab-tooltip-height;

$top-section-height: 5rem;