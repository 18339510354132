.whatsappgroup {
    display: flex;
    padding: 0 2rem;
    justify-content: center;
    // margin-block-end: 2rem;
    gap: 1rem;
    font-size: 1rem;
    min-height: 35rem;
    padding: 2rem 0 0 0;
    line-height: 1;

    h1 {
        font-size: 1.5rem;
    }

    .right {
        width: 45%;
        // max-height: 47.5vh;
        padding: 0 8rem;
        display: flex;
        flex-direction: column;
        // gap: 0.8rem;

        .input-container {
            margin-block-end: 0.5rem;
        }

        .input~label,
        select~label {
            top: 40%;
        }

        .input:focus~label,
        .input:active~label,
        .input:not([value=""])~label,
        select:focus~label,
        select:active~label,
        select:not([value=""])~label {
            transform: translateY(-95%);
        }

        .btn {
            margin-block-end: 0px;
            margin-block-end: 1rem;
        }

        .flex {
            display: flex;
            flex-direction: column;
            gap: 0.8rem;
        }


        .flex-between {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .left {
        padding: 0 8rem;
        // padding: 0 2rem;
        width: 45%;
        max-height: 45vh;
        //make background fit to common message color
        background: #f5f5f5;
        display: flex;
        align-items: center;
        text-align-last: right;
        font-size: 1rem;
        border: 1px solid $color-primary;
    }
}

.loading {
    width: 100%;
    height: 91vh;
    position: absolute;
    background: rgba($color-white, 0.8);
    z-index: 10;

    &__container {
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba($color-white, 0.8);
        z-index: 10;
    }

    &__circle {
        position: absolute;
        top: 35%;
        left: 40%;
        transform: translate(-50%, -50%);
        margin: auto;
        border: 20px solid #EAF0F6;
        border-radius: 50%;
        border-top: 20px solid $color-primary;
        width: 200px;
        height: 200px;
        animation: spinner 4s linear infinite;
    }
}



.res {
    position: absolute;
    // width: 10rem;
    background: white;
    border-radius: 5px;
    border: 1px solid $color-grey-dark-2;
    padding: 1rem;
    z-index: 10;

    &__title {
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 600;
        margin-block-end: 0.8rem;
        text-align: center;
    }

    &__subtitle {
        font-size: 1.2rem;
        line-height: 1;
        font-weight: 400;
        margin-block-end: 0.8rem;
    }

    &__success {
        background-color: rgba($color-success, 0.5);
        padding: 1rem;
        border-radius: 5px;
        line-height: 1;
    }

    &__fail {
        background-color: rgba($error-bg, 0.5);
        padding: 1rem;
        border-radius: 5px;
        line-height: 1;
        border: 1px solid;
    }
}