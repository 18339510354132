.singleFlight {
    &-flightInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-buttons {
        display: flex;
    }

    .flight {
        width: 80%;
        margin: auto;
    }


    &-files {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        width: 80%;
        margin: auto;
    }



    //flight details
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    h1 {
        margin-top: 1rem;
    }

    span {
        font-size: 1rem;
    }

    .flight {
        // border: 1px solid red;
        position: relative;
        display: flex;
        align-items: center;
        text-align: right;
        padding: 1rem;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        border: 1px solid $color-primary;
        width: 50%;

        &:nth-child(odd) {
            background-color: rgba($color: $color-primary, $alpha: .041);
            // color: $color-white;
        }

        &-info {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            margin-bottom: 0.5rem;
            justify-content: space-around;
            width: 100%;


            &__date,
            &__number {
                text-align: right;
            }

            &-text {
                font-size: 1.8rem;
                line-height: 2rem;
            }
        }

        &-actions {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 0.5rem;
        }
    }

    &__input {
        margin-top: 1rem;
        transform: scale(0.8);
    }
}