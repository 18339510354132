/*
0-350px: small phone
350-600px : phone
600-900px: tablet portarit
900-1070px: mini desktop
1070-1200px: tablet landscape
1200-1520px: small-desktop
[1200-1800px]: main style 
1800px + : big desktop

$breakpoint argument choices
-phone-small
-phone
-tab-port
-mini-desktop
-tab-land
-small-desktop
-big-desktop

1em =16px
*/
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes moveInBottomFull {
  0% {
    background-color: #ffffff;
    transform: translateY(-100%);
  }
  90% {
    transform: translateY(0.5rem);
  }
  100% {
    background-color: #ffffff;
    transform: translateY(0);
  }
}
@keyframes moveInTopFull {
  0% {
    transform: translateY(100%);
  }
  90% {
    transform: translateY(-0.5rem);
  }
  100% {
    transform: translateY(0);
  }
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  direction: rtl;
  overflow-x: hidden;
  cursor: default;
}

*:not(html) {
  overflow: hidden;
}

html {
  position: relative;
  scroll-behavior: smooth;
  min-height: 0vw;
}
html::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 1920px) {
  html {
    font-size: 1vw;
  }
}
@media only screen and (min-width: 1920px) {
  html {
    font-size: 19px;
  }
}

body {
  /* set the box size in all elements to border-box thanks to *{} */
  position: relative;
  box-sizing: border-box;
  width: 100vw;
  max-width: 1920px;
  margin-inline: auto;
  display: flex;
  background-color: #ffffff;
}
@media only screen and (max-width: 58.75em) {
  body {
    flex-direction: column;
  }
}

#root {
  width: 100%;
}

::selection {
  background-color: #0a56b8;
  color: #ffffff;
}

@font-face {
  font-family: "ploni";
  font-display: swap;
}
@font-face {
  font-family: "ploni-bold";
  font-display: swap;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v21/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFVUU1dYPFkZVOA6w.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
body {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7;
}

img {
  font-size: 1rem;
  line-height: 1rem;
}
@media only screen and (max-width: 58.75em) {
  img {
    font-size: 3rem;
    line-height: 3rem;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

h1 {
  font-size: 2.7rem;
  line-height: 2.7rem;
}

h2 {
  font-size: 1.45rem;
  line-height: 1.45rem;
}

h3 {
  font-size: 2.91rem;
  line-height: 3rem;
  letter-spacing: 0.03rem;
}
@media only screen and (max-width: 58.75em) {
  h3 {
    font-size: 4.1rem;
    line-height: initial;
    letter-spacing: 0.03rem;
  }
}

p strong {
  font-family: "ploni", sans-serif;
}

p {
  font-size: 0.9rem;
  line-height: 1rem;
}
@media only screen and (max-width: 58.75em) {
  p {
    font-size: 3rem;
    line-height: 3rem;
  }
}
p a {
  color: #777;
  text-decoration-color: #000;
  text-decoration-thickness: 3px;
}

a {
  cursor: pointer;
}

.blue-text {
  color: #03428E;
}

.orange-text {
  color: #D0B535;
}

body:not(.tabbed) *:focus {
  outline: none !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.no-animation {
  transition: none !important;
}
.no-animation::after, .no-animation::before {
  transition: none !important;
}

.u-hidden {
  display: none !important;
}

.u-desktop {
  display: block;
}
@media only screen and (max-width: 58.75em) {
  .u-desktop {
    display: none !important;
  }
}

.u-mobile {
  display: none !important;
}
@media only screen and (max-width: 58.75em) {
  .u-mobile {
    display: block !important;
  }
}

.show-on-scroll {
  opacity: 0;
  transform: translateY(50%);
  transition: all 0.5s;
  will-change: transform opacity;
}

.is-visible {
  opacity: 1;
  transform: translateY(0%);
}

.login {
  height: 96vh;
}
.login form {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: auto;
  padding-top: 15rem;
  gap: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .login form {
    width: 50%;
  }
}
.login form .submit {
  border: 2px solid #03428E;
  color: #ffffff;
  background-color: #03428E;
  padding: 0.6rem 1rem;
  text-align: center;
  font-size: 1.125rem;
  font-family: "ploni", sans-serif;
  align-items: center;
  cursor: pointer;
  position: relative;
  display: inline-block;
  outline: none;
  z-index: 1;
  transition: color 0.2s ease;
  text-decoration: none;
}
.login form .submit::after {
  position: absolute;
  content: "";
  width: calc(100% + 4px);
  height: 0;
  top: -2px;
  right: -2px;
  z-index: -1;
  background-color: #ffffff;
  cursor: pointer;
  transition: height 0.2s ease;
}
.login form .submit:hover, .login form .submit:focus {
  color: #03428E;
  border: 2px solid #03428E;
  cursor: pointer;
}
.login form .submit:hover::after, .login form .submit:focus::after {
  height: calc(100% + 4px);
}

.add-trip-container {
  display: flex;
  width: 100%;
}
.add-trip-container form {
  display: flex;
  width: 70vw;
  height: 100vh;
  padding: 2rem;
  justify-content: center;
  margin: auto;
}
.add-trip-container form .right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 55%;
}
.add-trip-container form .right .files-status {
  max-height: 25%;
  overflow: auto;
  border: 2px solid #03428E;
  border-radius: 10px;
  padding: 0 1rem;
  max-width: 70%;
  min-width: 70%;
  font-size: 1rem;
}
.add-trip-container form .right .files-status:empty {
  display: none;
}
.add-trip-container form .right .files-file {
  text-align: center;
}
.add-trip-container form .right .reset-files {
  font-size: 0.8rem;
}
.add-trip-container form .right svg.CircularProgressbar {
  position: absolute;
  width: 10rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.add-trip-container form .right .CircularProgressbar-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 5;
}
.add-trip-container form .right .CircularProgressbar-container.show {
  display: block;
}
.add-trip-container form .right .CircularProgressbar-container > p {
  position: absolute;
  top: 30%;
  right: 50%;
  transform: translate(50%, -50%);
  font-size: 2rem;
  line-height: 2.2rem;
}
.add-trip-container form .left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 100%;
  width: 70%;
}
.add-trip-container form .left .WysiwygAddFlight {
  width: 100%;
}
.add-trip-container form .left .input-container {
  width: 100%;
  height: 50%;
}
.add-trip-container form .left .input-container .textarea {
  width: 100%;
  height: 50%;
  border: 1px solid black;
  border-radius: 10px;
  padding: 1rem;
  font-size: 1rem;
  resize: none;
}
.add-trip-container form .left-dropzone {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 40%;
  width: 100%;
  border: 1px dashed black;
  border-radius: 10px;
}
.add-trip-container form .left-dropzone .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align-last: center;
  height: 20%;
  justify-content: space-evenly;
}

.lottie-uploading {
  width: 15rem;
}

.landing .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.landing h1 {
  margin-top: 1rem;
}
.landing span {
  font-size: 1rem;
}
.landing .flights {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem;
  width: 80%;
}
.landing .flight {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  border: 1px solid #03428E;
}
.landing .flight:nth-child(odd) {
  background-color: rgba(3, 66, 142, 0.041);
}
.landing .flight-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  justify-content: space-around;
  width: 80%;
}
.landing .flight-info__title {
  text-align: right;
  width: 40%;
}
.landing .flight-info__date, .landing .flight-info__number {
  text-align: right;
}
.landing .flight-info-text {
  font-size: 1.5rem;
  line-height: 1.7rem;
}
.landing .flight-actions {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}
.landing__input {
  width: 15%;
  margin-top: 1rem;
  transform: scale(0.8);
}

.totalFlights {
  position: fixed;
  right: 1rem;
  top: 80%;
  background: #02428e;
  border: 1px solid;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-weight: bold;
  color: #333;
}
.totalFlights_number {
  font-size: 1.8rem;
  line-height: 1;
}

.singleFlight-flightInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.singleFlight-buttons {
  display: flex;
}
.singleFlight .flight {
  width: 80%;
  margin: auto;
}
.singleFlight-files {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 80%;
  margin: auto;
}
.singleFlight .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.singleFlight h1 {
  margin-top: 1rem;
}
.singleFlight span {
  font-size: 1rem;
}
.singleFlight .flight {
  position: relative;
  display: flex;
  align-items: center;
  text-align: right;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #03428E;
  width: 50%;
}
.singleFlight .flight:nth-child(odd) {
  background-color: rgba(3, 66, 142, 0.041);
}
.singleFlight .flight-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  justify-content: space-around;
  width: 100%;
}
.singleFlight .flight-info__date, .singleFlight .flight-info__number {
  text-align: right;
}
.singleFlight .flight-info-text {
  font-size: 1.8rem;
  line-height: 2rem;
}
.singleFlight .flight-actions {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}
.singleFlight__input {
  margin-top: 1rem;
  transform: scale(0.8);
}

.custom-mail-container {
  padding: 3rem;
}
.custom-mail-container .top-inputs {
  display: flex;
  justify-content: center;
  gap: 2rem;
  overflow: visible;
  margin-block-end: 4rem;
}
.custom-mail-container .top-inputs .input ~ label {
  top: 40%;
}
.custom-mail-container .top-inputs input:focus ~ label,
.custom-mail-container .top-inputs .input:active ~ label,
.custom-mail-container .top-inputs .input:not([value=""]) ~ label {
  transform: translateY(-100%);
}
.custom-mail-container .checkbox {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-inline-start: 8.2rem;
}
.custom-mail-container .small {
  width: 70%;
  margin: 0 auto 2rem;
}
.custom-mail-container .bottom {
  text-align: center;
  padding: 2rem;
  border: 1px solid;
  border-radius: 1rem;
  margin: 2rem;
}

.control,
.whatsppDocket {
  overflow: visible;
}
.control .dockets,
.whatsppDocket .dockets {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem 2%;
  overflow: visible;
}
.control .dockets .docket-card,
.whatsppDocket .dockets .docket-card {
  border: 1px solid rgba(153, 153, 153, 0.25);
  border-radius: 1rem;
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.15);
  padding: 2rem 0;
  gap: 1rem;
  width: 20%;
  overflow: visible;
}
.control .dockets .docket-card.red,
.whatsppDocket .dockets .docket-card.red {
  background: rgba(220, 53, 69, 0.5);
}
.control .dockets .docket-card-top,
.whatsppDocket .dockets .docket-card-top {
  padding: 0 1rem;
}
.control .dockets .docket-card-top-bg,
.whatsppDocket .dockets .docket-card-top-bg {
  background: whitesmoke;
  border: 1px solid;
  padding: 0.7rem;
  border-radius: 5px;
}
.control .dockets .docket-card-top-left,
.whatsppDocket .dockets .docket-card-top-left {
  display: flex;
  gap: 1rem;
}
.control .dockets .docket-card-bottom,
.whatsppDocket .dockets .docket-card-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 1rem 1rem 0 1rem;
  gap: 1rem;
}
.control .dockets .docket-card-bottom .passenger,
.whatsppDocket .dockets .docket-card-bottom .passenger {
  border: 1px solid #03428E;
  padding: 1rem;
  font-size: 1rem;
  width: 100%;
  border: 1px solid rgba(153, 153, 153, 0.25);
  border-radius: 1rem;
}
.control .dockets .docket-card-bottom .passenger-img,
.whatsppDocket .dockets .docket-card-bottom .passenger-img {
  width: 50%;
  aspect-ratio: 9/16;
  object-fit: cover;
}

.whatsappgroup {
  display: flex;
  padding: 0 2rem;
  justify-content: center;
  gap: 1rem;
  font-size: 1rem;
  min-height: 35rem;
  padding: 2rem 0 0 0;
  line-height: 1;
}
.whatsappgroup h1 {
  font-size: 1.5rem;
}
.whatsappgroup .right {
  width: 45%;
  padding: 0 8rem;
  display: flex;
  flex-direction: column;
}
.whatsappgroup .right .input-container {
  margin-block-end: 0.5rem;
}
.whatsappgroup .right .input ~ label,
.whatsappgroup .right select ~ label {
  top: 40%;
}
.whatsappgroup .right .input:focus ~ label,
.whatsappgroup .right .input:active ~ label,
.whatsappgroup .right .input:not([value=""]) ~ label,
.whatsappgroup .right select:focus ~ label,
.whatsappgroup .right select:active ~ label,
.whatsappgroup .right select:not([value=""]) ~ label {
  transform: translateY(-95%);
}
.whatsappgroup .right .btn {
  margin-block-end: 0px;
  margin-block-end: 1rem;
}
.whatsappgroup .right .flex {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.whatsappgroup .right .flex-between {
  flex-direction: row;
  justify-content: space-between;
}
.whatsappgroup .left {
  padding: 0 8rem;
  width: 45%;
  max-height: 45vh;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  text-align-last: right;
  font-size: 1rem;
  border: 1px solid #03428E;
}

.loading {
  width: 100%;
  height: 91vh;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
}
.loading__container {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
}
.loading__circle {
  position: absolute;
  top: 35%;
  left: 40%;
  transform: translate(-50%, -50%);
  margin: auto;
  border: 20px solid #EAF0F6;
  border-radius: 50%;
  border-top: 20px solid #03428E;
  width: 200px;
  height: 200px;
  animation: spinner 4s linear infinite;
}

.res {
  position: absolute;
  background: white;
  border-radius: 5px;
  border: 1px solid #999;
  padding: 1rem;
  z-index: 10;
}
.res__title {
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 600;
  margin-block-end: 0.8rem;
  text-align: center;
}
.res__subtitle {
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 400;
  margin-block-end: 0.8rem;
}
.res__success {
  background-color: rgba(6, 192, 14, 0.5);
  padding: 1rem;
  border-radius: 5px;
  line-height: 1;
}
.res__fail {
  background-color: rgba(220, 53, 70, 0.5);
  padding: 1rem;
  border-radius: 5px;
  line-height: 1;
  border: 1px solid;
}

.input,
select {
  position: relative;
  padding: 1rem;
  padding-block-end: 0;
  border: none;
  border-bottom: 2px solid #333;
  overflow: visible;
  font-size: 1.2rem;
}
.input:focus, .input:active, .input:not([value=""]),
select:focus,
select:active,
select:not([value=""]) {
  outline: none;
  border-bottom: 2px solid #03428E;
}
.input:focus ~ label, .input:active ~ label, .input:not([value=""]) ~ label,
select:focus ~ label,
select:active ~ label,
select:not([value=""]) ~ label {
  color: #03428E;
  position: absolute;
  padding-right: 0rem;
  transform: translateY(-120%);
  transition: all 0.2s ease-in-out;
}
.input::placeholder,
select::placeholder {
  color: #333;
}
.input--error,
select--error {
  border-bottom: 2px solid rgba(220, 53, 69, 0.5215686275);
}
.input--error::placeholder,
select--error::placeholder {
  color: rgba(220, 53, 69, 0.5215686275);
}
.input--success,
select--success {
  border-bottom: 1px solid #03428E;
}
.input--success::placeholder,
select--success::placeholder {
  color: #03428E;
}
.input--disabled,
select--disabled {
  border-bottom: 1px solid #333;
  background-color: #ececec;
  color: #333;
}
.input-container,
select-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: visible;
}
.input-container.checkbox,
select-container.checkbox {
  flex-direction: row;
}
.input ~ label,
select ~ label {
  position: absolute;
  display: block;
  top: 50%;
  padding-right: 0.5rem;
  pointer-events: none;
  font-size: 1.1rem;
  line-height: 1.3rem;
  transition: all 0.2s ease-in-out;
}
.input::before,
select::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #03428E;
  transition: all 0.4s ease-in-out;
}
.input ~ span,
select ~ span {
  position: absolute;
  bottom: 0%;
  right: 0;
  font-size: 1rem;
  transform: translate(0%, 100%);
  color: grey;
}

select {
  padding: 0;
  margin-block-start: 1rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background-color: #03428E;
  color: #ffffff;
  height: 4rem;
  width: 100%;
}
.navbar img.logo {
  height: auto;
  object-fit: contain;
  background: white;
  padding: 0.5rem;
  width: 9rem;
  margin: 0 1rem;
}
.navbar .navbar__logo {
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
.navbar .navbar__links {
  display: flex;
  align-items: center;
}
.navbar .navbar__links .navbar__link {
  margin: 0 1rem;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  transition: all 0.2s ease-in-out;
}
.navbar .navbar__links .navbar__link:hover {
  color: #000;
  background-color: #D0B535;
  filter: drop-shadow(0px -1px 6px black);
}
.navbar .navbar__links .navbar__link--active {
  background-color: #D0B535;
  filter: drop-shadow(0px -1px 6px black);
  color: #D0B535;
}

.alert {
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  display: flex;
  gap: 1rem;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
}
.alert-container {
  display: relative;
  position: fixed;
  top: 6rem;
  right: 50%;
  max-width: 80%;
  padding: 1rem;
  transform: translateX(50%);
  position: absolute;
  text-align: center;
  z-index: 100;
  overflow: visible;
}
.alert-general {
  text-align: center;
  color: #000;
  background-color: rgba(208, 181, 53, 0.6);
  border: 1px solid #D0B535;
}
.alert-danger {
  text-align: center;
  color: rgba(220, 53, 69, 0.5215686275);
  background-color: rgba(220, 53, 69, 0.4);
  border: 1px solid rgba(220, 53, 69, 0.5215686275);
}
.alert-success {
  text-align: center;
  color: #06c00e;
  color: #000;
  background-color: rgba(6, 192, 14, 0.4);
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  position: relative;
  z-index: 100;
  border: 1px solid #06c00e;
}
.alert-success-icon {
  font-size: 2rem;
  margin-right: 1rem;
}
.alert-success-close {
  font-size: 2rem;
  cursor: pointer;
}
.alert .closebtn {
  color: #999;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
}

.x-inside-solid-circle {
  color: rgba(220, 53, 69, 0.5215686275);
}
.x-inside-solid-circle.upload-form {
  width: 10%;
}

.plus-in-circle {
  color: #06c00e;
}
.plus-in-circle.upload-form {
  width: 10%;
}

.btn * {
  cursor: pointer;
}
.btn-primary {
  border: 2px solid #03428E;
  color: #ffffff;
  background-color: #03428E;
  padding: 0.6rem 1rem;
  text-align: center;
  font-size: 1.125rem;
  font-family: "ploni", sans-serif;
  align-items: center;
  cursor: pointer;
  position: relative;
  display: inline-block;
  outline: none;
  z-index: 1;
  transition: color 0.2s ease;
  text-decoration: none;
  border-radius: 10px;
}
.btn-primary::after {
  position: absolute;
  content: "";
  width: calc(100% + 4px);
  height: 0;
  top: -2px;
  right: -2px;
  z-index: -1;
  background-color: #ffffff;
  cursor: pointer;
  transition: height 0.2s ease;
}
.btn-primary:hover, .btn-primary:focus {
  color: #03428E;
  border: 2px solid #03428E;
  cursor: pointer;
}
.btn-primary:hover::after, .btn-primary:focus::after {
  height: calc(100% + 4px);
}
.btn-secondary {
  border: 2px solid #D0B535;
  color: #ffffff;
  background-color: #D0B535;
  padding: 0.6rem 1rem;
  text-align: center;
  font-size: 1.125rem;
  font-family: "ploni", sans-serif;
  align-items: center;
  cursor: pointer;
  position: relative;
  display: inline-block;
  outline: none;
  z-index: 1;
  transition: color 0.2s ease;
  text-decoration: none;
  border-radius: 10px;
}
.btn-secondary::after {
  position: absolute;
  content: "";
  width: calc(100% + 4px);
  height: 0;
  top: -2px;
  right: -2px;
  z-index: -1;
  background-color: #ffffff;
  cursor: pointer;
  transition: height 0.2s ease;
}
.btn-secondary:hover, .btn-secondary:focus {
  color: #D0B535;
  border: 2px solid #D0B535;
  cursor: pointer;
}
.btn-secondary:hover::after, .btn-secondary:focus::after {
  height: calc(100% + 4px);
}
.btn-secondary:hover {
  color: #03428E;
}
.btn-warning {
  border: 2px solid rgba(220, 53, 69, 0.5215686275);
  color: #ffffff;
  background-color: #dc3546;
  padding: 0.6rem 1rem;
  text-align: center;
  font-size: 1.125rem;
  font-family: "ploni", sans-serif;
  align-items: center;
  cursor: pointer;
  position: relative;
  display: inline-block;
  outline: none;
  z-index: 1;
  transition: color 0.2s ease;
  text-decoration: none;
  border-radius: 10px;
}
.btn-warning::after {
  position: absolute;
  content: "";
  width: calc(100% + 4px);
  height: 0;
  top: -2px;
  right: -2px;
  z-index: -1;
  background-color: #ffffff;
  cursor: pointer;
  transition: height 0.2s ease;
}
.btn-warning:hover, .btn-warning:focus {
  color: #dc3546;
  border: 2px solid rgba(220, 53, 69, 0.5215686275);
  cursor: pointer;
}
.btn-warning:hover::after, .btn-warning:focus::after {
  height: calc(100% + 4px);
}

.loader-background {
  background-color: rgba(3, 66, 142, 0.6);
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
}

#loader {
  /* Uncomment this to make it run! */
  /*
     animation: loader 5s linear infinite; 
  */
  position: relative;
  top: 50%;
  left: 50%;
  width: 100vw;
  margin: auto;
  height: 80px;
  transform: translate(-50%, -50%);
}

@keyframes loader {
  0% {
    left: -100px;
  }
  100% {
    left: 110%;
  }
}
#box {
  width: 50px;
  height: 50px;
  background: #D0B535;
  animation: animate 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 50%;
  border-radius: 3px;
}

@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
#shadow {
  width: 50px;
  height: 5px;
  background: #000;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 50%;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}

@keyframes shadow {
  50% {
    transform: scale(1.2, 1);
  }
}

