@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// @keyframes changingBorderRadius {
//   0% {
//     border-radius: 30% 50% 20% 50%;
//   }

//   25% {
//     border-radius: 50% 25% 50% 40%;
//   }

//   50% {
//     border-radius: 45% 60% 25% 30%;
//   }

//   // 50% {
//   //   border-radius: 45% 30% 25% 30%;
//   // }

//   75% {
//     border-radius: 50% 30% 50% 20%;
//   }

//   100% {
//     border-radius: 30% 50% 20% 50%;
//   }
// }

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// @keyframes fadeOut {
//   0% {
//     opacity: 1;
//   }

//   100% {
//     opacity: 0;
//   }
// }

// @keyframes fadeInOut {
//   0% {
//     opacity: 0;
//   }

//   20% {
//     opacity: 1;
//   }

//   80% {
//     opacity: 1;
//   }

//   100% {
//     opacity: 0;
//   }
// }

// @keyframes moveInTop {
//   0% {
//     opacity: 0;
//     transform: translateY(15%);
//   }

//   90% {
//     transform: translateY(-0.5rem);
//   }

//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }

@keyframes moveInBottomFull {
  0% {
    background-color: $color-white;
    transform: translateY(-100%);
  }

  90% {
    transform: translateY(0.5rem);
  }

  100% {
    background-color: $color-white;
    transform: translateY(0);
  }
}

@keyframes moveInTopFull {
  0% {
    transform: translateY(100%);
  }

  90% {
    transform: translateY(-0.5rem);
  }

  100% {
    transform: translateY(0);
  }
}

// @keyframes moveInLeft {
//   0% {
//     opacity: 0;
//     transform: translateX(20%);
//   }

//   90% {
//     transform: translateX(-0.5rem);
//   }

//   100% {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }

// @keyframes moveInRight {
//   0% {
//     opacity: 0;
//     transform: translateX(-20%);
//   }

//   90% {
//     transform: translateX(1rem);
//   }

//   100% {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }