// .tabbed *:focus {
//     outline: $color-primary solid 5px;
// }

body:not(.tabbed) *:focus {
    outline: none !important;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.no-animation {
    transition: none !important;

    &::after,
    &::before {
        transition: none !important;
    }
}