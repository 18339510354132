.btn {
    & * {
        cursor: pointer;
    }

    &-primary {
        @include global-btn($color-white, $color-primary, $color-primary);
        border-radius: 10px;
    }

    &-secondary {
        @include global-btn($color-white, $color-secondary, $color-secondary);
        border-radius: 10px;

        &:hover {
            color: $color-primary;
        }
    }

    &-warning {
        @include global-btn($color-white, $error-bg, $error-border);
        border-radius: 10px;
    }
}