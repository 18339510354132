@mixin clearFix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin absltCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin inputBasic {
  font-family: 'ploni', sans-serif;
  height: 9rem;
  border: none;
  border-radius: 10rem;
  margin: 1.5rem 0;
  padding: 0 2.5rem;
  font-size: 3.5rem;

  @include respond(phone) {
    height: 7rem;
    margin-bottom: 1rem;
    font-size: 3rem;
  }

  @include respond(phone-small) {
    margin: 0.8rem;
  }
}

@mixin submitBasic($color) {
  font-family: 'ploni-bold', sans-serif;
  position: relative;
  height: 10rem;
  border: none;
  border-radius: 10rem;
  margin: 2rem 0;
  padding: 0 2rem;
  font-size: 5rem;
  color: $color-white;
  background-color: $color;
  width: 50%;
  align-self: center;
  margin-top: 9rem;

  transition: all 0.5s;

  @include respond(phone) {
    font-size: 3rem;
    width: 50%;
    height: 7rem;
    margin-top: 5rem;
  }
}

@mixin buttonHover {
  transform: translate(0.6rem, -0.8rem);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.6);
  cursor: pointer;
}


@mixin global-btn ($color, $bg, $borderColor) {
  border: 2px solid $borderColor;
  color: $color;
  background-color: $bg;
  padding: 0.6rem 1rem;
  text-align: center;
  font-size: 1.125rem;
  font-family: "ploni", sans-serif;
  align-items: center;
  cursor: pointer;
  position: relative;
  display: inline-block;
  outline: none;
  z-index: 1;
  transition: color 0.2s ease;
  text-decoration: none;

  &::after {
    position: absolute;
    content: "";
    width: calc(100% + 4px);
    height: 0;
    top: -2px;
    right: -2px;
    z-index: -1;
    background-color: $color;
    cursor: pointer;
    transition: height 0.2s ease;
  }

  &:hover,
  &:focus {
    color: $bg;
    border: 2px solid $borderColor;
    cursor: pointer;

    &::after {
      height: calc(100% + 4px);
    }
  }
}



// --------- BUILT BY Amit Ashdot ---------
//Media query manager
/*
0-350px: small phone
350-600px : phone
600-900px: tablet portarit
900-1070px: mini desktop
1070-1200px: tablet landscape
1200-1520px: small-desktop
[1200-1800px]: main style 
1800px + : big desktop

$breakpoint argument choices
-phone-small
-phone
-tab-port
-mini-desktop
-tab-land
-small-desktop
-big-desktop

1em =16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint==phone-small {
    @media only screen and (max-width: 26em) {
      // 350px - iphone 5 (415px)
      @content;
    }
  }

  @if $breakpoint==phone {
    @media only screen and (max-width: 37.5em) {
      // 600px
      @content;
    }
  }

  @if $breakpoint==tab-port {
    @media only screen and (max-width: 58.75em) {
      // 900px
      @content;
    }
  }

  @if $breakpoint==mini-desktop {
    @media only screen and (max-width: 66.875em) {
      // 1070px
      @content;
    }
  }

  @if $breakpoint==tab-land {
    @media only screen and (max-width: 75em) {
      // 1200px
      @content;
    }
  }

  @if $breakpoint==small-desktop {
    @media only screen and (max-width:95em) {
      // 1520px
      @content;
    }
  }

  @if $breakpoint==normal-desktop {
    @media only screen and (max-width: 112.5em) {
      // 1800px
      @content;
    }
  }

  @if $breakpoint==big-desktop-max {
    @media only screen and (max-width: 1920px) {
      // 1920px
      @content;
    }
  }

  @if $breakpoint==big-desktop-min {
    @media only screen and (min-width: 1920px) {
      // 1920px
      @content;
    }
  }
}






//- Mixin: Fluid Type
///
/// Magic calc + vh to allow text to be fluid between minimum
/// and maximum breakpoints.
///
/// @group typography
/// @param {variable} $min-font-size [12px] - Minimum font size 
/// @param {variable} $max-font-size [24px] - Maximum font size
/// @param {variable} $lower-range [420px] - Stop scaling font smaller at this screen resolution 
/// @param {variable} $upper-range [900px] - Stop scaling font larger at this screen resolution 
/// @example
///   h1 {
///     @include responsive-type(20px, 48px);
///   }
/// @site https://madebymike.com.au/writing/precise-control-responsive-typography/