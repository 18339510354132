.landing {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    h1 {
        margin-top: 1rem;
    }

    span {
        font-size: 1rem;
    }

    .flights {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 3rem;
        width: 80%;
    }

    .flight {
        // border: 1px solid red;
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 1rem;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        justify-content: space-between;
        border: 1px solid $color-primary;

        &:nth-child(odd) {
            background-color: rgba($color: $color-primary, $alpha: .041);
            // color: $color-white;
        }

        &-info {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            margin-bottom: 0.5rem;
            justify-content: space-around;
            width: 80%;

            &__title {
                text-align: right;
                width: 40%;
            }

            &__date,
            &__number {
                text-align: right;
            }

            &-text {
                font-size: 1.5rem;
                line-height: 1.7rem;
            }
        }

        &-actions {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 0.5rem;
        }
    }

    &__input {
        width: 15%;
        margin-top: 1rem;
        transform: scale(0.8);
    }
}


.totalFlights {
    position: fixed;
    right: 1rem;
    top: 80%;
    background: #02428e;
    border: 1px solid;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    font-weight: bold;
    color: #333;

    &_number {
        font-size: 1.8rem;
        line-height: 1;
    }
}