.custom-mail-container {
    padding: 3rem;


    & .top-inputs {
        display: flex;
        justify-content: center;
        gap: 2rem;
        overflow: visible;
        margin-block-end: 4rem;

        & .input~label {
            top: 40%;


        }

        input:focus~label,
        .input:active~label,
        .input:not([value=""])~label {
            transform: translateY(-100%);
        }
    }

    .checkbox {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-inline-start: 8.2rem;

    }

    .small {
        width: 70%;
        margin: 0 auto 2rem;
    }

    .bottom {
        text-align: center;
        padding: 2rem;
        border: 1px solid;
        border-radius: 1rem;
        margin: 2rem;
    }
}