.login {
    height: 96vh;


    form {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 30%;
        margin: auto;
        padding-top: 15rem;
        gap: 2rem;


        @include respond(phone) {
            width: 50%;
        }

        .submit {
            @include global-btn($color-white, $color-primary, $color-primary);
        }
    }
}