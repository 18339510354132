.control,
.whatsppDocket {
    overflow: visible;

    &-container {}

    &-top {}

    &-kav {}

    .dockets {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem 2%;
        overflow: visible;

        .docket-card {
            border: 1px solid rgba($color-grey-dark-2, .25);
            border-radius: 1rem;

            box-shadow: 0 0.3rem 1rem rgba($color-black, .15);
            padding: 2rem 0;
            gap: 1rem;
            width: 20%;
            overflow: visible;

            &.red {
                background: rgba($error-border, .5)
            }

            &-top {
                padding: 0 1rem;

                &-bg {
                    background: whitesmoke;
                    border: 1px solid;
                    padding: 0.7rem;
                    border-radius: 5px;
                }

                &-left {
                    display: flex;
                    gap: 1rem;
                }
            }


            &-bottom {
                display: flex;
                flex-wrap: wrap;
                // flex-direction: column;
                justify-content: space-evenly;
                padding: 1rem 1rem 0 1rem;
                gap: 1rem;

                .passenger {
                    border: 1px solid $color-primary;
                    padding: 1rem;
                    font-size: 1rem;
                    width: 100%;
                    border: 1px solid rgba($color-grey-dark-2, .25);
                    border-radius: 1rem;

                    &-img {
                        // display: none;
                        width: 50%;
                        aspect-ratio: 9/16;
                        object-fit: cover;
                    }

                }
            }


        }
    }
}