.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    background-color: $color-primary;
    color: $color-white;
    height: 4rem;
    width: 100%;


    img.logo {
        height: auto;
        object-fit: contain;
        background: white;
        padding: 0.5rem;
        width: 9rem;
        margin: 0 1rem;
    }

    .navbar__logo {
        display: flex;
        font-size: 1.5rem;
        font-weight: 700;
        letter-spacing: 0.1rem;
        text-transform: uppercase;

    }

    .navbar__links {
        display: flex;
        align-items: center;

        .navbar__link {
            margin: 0 1rem;
            font-size: 1rem;
            font-weight: 500;
            letter-spacing: 0.1rem;
            text-transform: uppercase;
            color: $color-white;
            text-decoration: none;

            height: 4rem;
            display: flex;
            align-items: center;
            padding: 0 1rem;


            transition: all 0.2s ease-in-out;

            &:hover {
                color: $color-black;
                background-color: $color-secondary;
                filter: drop-shadow(0px -1px 6px black);
            }

            &--active {
                background-color: $color-secondary;
                filter: drop-shadow(0px -1px 6px black);
                color: $color-secondary;
            }

            // &:visited {
            //     color: $color-white;
            // }

            // &:active {
            //     color: $color-white;
            // }
        }
    }
}