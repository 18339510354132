.loader-background {
    background-color: rgba($color: $color-primary, $alpha: .6);
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
}

#loader {

    /* Uncomment this to make it run! */
    /*
     animation: loader 5s linear infinite; 
  */

    position: relative;
    top: 50%;
    left: 50%;
    width: 100vw;
    margin: auto;
    height: 80px;
    transform: translate(-50%, -50%);
}

@keyframes loader {
    0% {
        left: -100px
    }

    100% {
        left: 110%;
    }
}

#box {
    width: 50px;
    height: 50px;
    background: $color-secondary;
    animation: animate .5s linear infinite;
    position: absolute;
    top: 0;
    left: 50%;
    border-radius: 3px;
}

@keyframes animate {
    17% {
        border-bottom-right-radius: 3px;
    }

    25% {
        transform: translateY(9px) rotate(22.5deg);
    }

    50% {
        transform: translateY(18px) scale(1, .9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }

    75% {
        transform: translateY(9px) rotate(67.5deg);
    }

    100% {
        transform: translateY(0) rotate(90deg);
    }
}

#shadow {
    width: 50px;
    height: 5px;
    background: #000;
    opacity: 0.1;
    position: absolute;
    top: 59px;
    left: 50%;
    border-radius: 50%;
    animation: shadow .5s linear infinite;
}

@keyframes shadow {
    50% {
        transform: scale(1.2, 1);
    }
}