.input,
select {
    position: relative;
    padding: 1rem;
    padding-block-end: 0;
    border: none;
    border-bottom: 2px solid $color-grey-dark-3;
    overflow: visible;
    font-size: 1.2rem;

    &:focus,
    &:active,
    &:not([value=""]) {
        outline: none;
        border-bottom: 2px solid $color-primary;


        &~label {
            color: $color-primary;
            position: absolute;
            padding-right: 0rem;
            transform: translateY(-120%);
            transition: all 0.2s ease-in-out;
        }

    }

    &::placeholder {
        color: $color-grey-dark-3;
    }

    &--error {
        border-bottom: 2px solid $error-border;
    }

    &--error::placeholder {
        color: $error-border;
    }

    &--success {
        border-bottom: 1px solid $color-primary;
    }

    &--success::placeholder {
        color: $color-primary;
    }

    &--disabled {
        border-bottom: 1px solid $color-grey-dark-3;
        background-color: $color-grey-light-2;
        color: $color-grey-dark-3;
    }


    &-container {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow: visible;

        &.checkbox {
            flex-direction: row;
        }
    }

    &~label {
        position: absolute;
        display: block;
        // height: 1.3rem;
        top: 50%;
        padding-right: 0.5rem;
        pointer-events: none;
        font-size: 1.1rem;
        line-height: 1.3rem;
        transition: all 0.2s ease-in-out;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $color-primary;
        transition: all 0.4s ease-in-out;
    }

    &~span {
        position: absolute;
        bottom: 0%;
        right: 0;
        font-size: 1rem;
        transform: translate(0%, 100%);
        color: grey;
    }
}

select {
    padding: 0;
    margin-block-start: 1rem;
}